<template>
  <b-modal
    id="calendar-schedule-add-modal"
    :title="computedTitle"
    size="xl"
    centered
    hide-footer
    @show="onShowForm"
    @hide="handleResetForm"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <!-- Tên lịch hẹn -->
        <basic-input
          v-validate="{
            required: true,
          }"
          :value.sync="schedule.name"
          :state="validateState('name')"
          :invalidFeedback="errors.first('name')"
          name="name"
          required
          data-vv-as="Tên lịch hẹn"
          label="Tên lịch hẹn"
          maxLength="50"
          placeholder="Nhập tên lịch hẹn"
        />

        <!-- Loại -->
        <basic-select
          v-validate="{
            required: true,
          }"
          :value.sync="schedule.type"
          :options="optionTypes"
          :disabled="isEdit || isInputInterview || isCoaching"
          :state="validateState('type')"
          :invalidFeedback="errors.first('type')"
          name="type"
          required
          data-vv-as="Loại"
          value-label="text"
          label="Loại"
          track-by="value"
          placeholder="---Chọn loại---"
          changeValueByObject
          @input="onInputType"
        />

        <!-- Tên hoạt động -->
        <basic-select
          v-if="isOneAndMultiple"
          v-validate="{
            required: true,
          }"
          :value.sync="schedule.activity"
          :options="activites"
          :disabled="isEdit"
          :state="validateState('activity')"
          :invalidFeedback="errors.first('activity')"
          name="activity"
          required
          data-vv-as="Tên hoạt động"
          value-label="text"
          label="Tên hoạt động"
          track-by="value"
          placeholder="---Chọn tên hoạt động---"
          changeValueByObject
          @input="$validator.reset({ name: 'activity' })"
        />

        <!-- Ngày hẹn - Giờ hẹn -->
        <b-row>
          <!-- Ngày hẹn -->
          <b-col>
            <!-- <basic-date-picker
              v-model="schedule.appointmentDate"
              v-validate="{
                required: true,
              }"
              :state="validateState('appointmentDate')"
              :invalidFeedback="errors.first('appointmentDate')"
              :format="dateFomartion"
              :disabled="isEdit"
              :disabled-dates="nowDate"
              required
              name="appointmentDate"
              data-vv-as="Ngày hẹn"
              label="Ngày hẹn"
              placeholder="Chọn ngày hẹn"
              @input="$validator.reset({ name: 'appointmentDate' })"
            /> -->
            <label class="d-block" for="appointmentDate">Ngày hẹn</label>
            <b-form-group
              :id="`appointmentDate`"
              :invalid-feedback="errors.first('appointmentDate')"
              :state="validateState('appointmentDate')"
              class="required"
              label-for="appointmentDate"
            >
              <date-picker
                id="appointmentDate"
                placeholder="Chọn ngày hẹn"
                name="appointmentDate"
                v-validate="`required`"
                input-class="form-control"
                type="date"
                value-type="format"
                data-vv-as="Ngày hẹn"
                :format="dateFomartion"
                v-model="schedule.appointmentDate"
                :default-value="new Date()"
                :disabled="isEdit"
                :disabled-date="disabledBeforeTodayAndAfterTwoWeek"
                @input="$validator.reset({ name: 'appointmentDate' })"
              >
                <template v-slot:icon-calendar>
                  <span class="svg-icon">
                    <inline-svg src="/media/svg/icons/Neolex/Time/icon.svg" />
                  </span>
                </template>
              </date-picker>
            </b-form-group>
          </b-col>

          <!-- Từ - Đến -->
          <b-col cols="5" class="form-group required">
            <label class="d-block">Thời gian</label>
            <b-row>
              <b-col>
                <b-form-group
                  :id="`fromTime`"
                  :invalid-feedback="errors.first('fromTime')"
                  :state="validateState('fromTime')"
                  class="required"
                  label-for="fromTime"
                >
                  <date-picker
                    id="fromTime"
                    placeholder="Giờ hẹn"
                    name="fromTime"
                    v-validate="
                      `required|min_time:${validMinFromTime}|max_time:${toTime}`
                    "
                    input-class="form-control"
                    type="time"
                    data-vv-as="Giờ hẹn"
                    value-type="format"
                    format="H:mm"
                    v-model="fromTime"
                    :disabled="isEdit"
                    @clear="fromTime = ''"
                    @change="selectFromTime"
                  >
                    <template v-slot:icon-calendar>
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Time/icon.svg"
                        />
                      </span>
                    </template>
                  </date-picker>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :id="`toTime`"
                  :invalid-feedback="errors.first('toTime')"
                  :state="validateState('toTime')"
                  class="required"
                  label-for="toTime"
                >
                  <date-picker
                    id="toTime"
                    placeholder="Giờ kết thúc"
                    name="toTime"
                    v-validate="`required|min_time:${fromTime}`"
                    input-class="form-control"
                    type="time"
                    data-vv-as="Giờ kết thúc"
                    value-type="format"
                    format="H:mm"
                    v-model="toTime"
                    :disabled="isEdit || isInputInterview"
                    @clear="toTime = ''"
                  >
                    <template v-slot:icon-calendar>
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Time/icon.svg"
                        />
                      </span>
                    </template>
                  </date-picker>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Tần suất -->
          <b-col :class="['form-group', { 'is-invalid': isErrorApptDate }]">
            <basic-select
              v-model="schedule.repeatType"
              v-validate="{
                required: true,
              }"
              :state="validateState('repeatType')"
              :invalidFeedback="errors.first('repeatType')"
              :options="formRepeatTypes"
              :disabled="isEdit || disableRepeatType || isInputInterview"
              name="repeatType"
              required
              data-vv-as="Tần suất"
              value-label="text"
              label="Tần suất"
              track-by="value"
              placeholder="Tần suất"
              @input="onChangeLoopSchedule"
            />
            <div
              v-if="isErrorApptDate"
              class="invalid-feedback valid-mess d-block"
            >
              Ngày kết thúc phải sau ngày hẹn
            </div>
            <div v-if="isCustomRepeat">
              {{ computedRepeatText }}
            </div>
          </b-col>
        </b-row>

        <!-- Group Coach - Người tham gia -->
        <b-row v-if="!isEdit">
          <!-- Group Coach -->
          <b-col>
            <basic-select
              v-model="selectedCoaches"
              :options="computedCoaches"
              :disabled="isEdit || isOneAndOne || isInputInterview"
              value-label="name"
              track-by="id"
              name="groupCoach"
              label="Group coach"
              multiple
              changeValueByObject
              placeholder="---Chọn Group coach---"
              :required="isOneAndMultiple"
              v-validate="{
                required: isOneAndMultiple,
              }"
              :state="validateState('groupCoach')"
              :invalidFeedback="errors.first('groupCoach')"
              data-vv-as="Group coach"
              @input="validateCbPatients"
            />
          </b-col>

          <!-- Người tham gia -->
          <b-col>
            <basic-select
              v-model="selectedPatients"
              :options="computedPatients"
              :disabled="isEdit || isInputInterview"
              :multiple="!isOneAndOne"
              value-label="fullName"
              track-by="accountId"
              name="patient"
              label="Người tham gia"
              changeValueByObject
              placeholder="---Chọn người tham gia---"
              @input="validateCbPatients"
            >
              <template
                v-if="
                  isOneAndOne && totalLoadedPatients > loadedPatients.length
                "
                #loadMore
              >
                <b-overlay :show="isLoadPatients" rounded="lg" opacity="0.6">
                  <li
                    class="multiselect__load-more"
                    @click.stop="loadMorePatients"
                  >
                    <a href="javascript:;">Hiển thị thêm... </a>
                  </li>
                </b-overlay>
              </template>
            </basic-select>
          </b-col>
        </b-row>

        <!-- Danh sách người tham gia -->
        <div class="mb-4 form-group required">
          <label class="d-block">Danh sách người tham gia</label>
          <div
            :class="[
              'patients form-control',
              {
                'is-invalid': isErrorCbPatients,
              },
            ]"
          >
            <span
              v-for="patient in combinedPatients"
              :key="patient.accountId"
              class="patients__item"
            >
              {{ patient.fullName }}
              <b-icon-x
                v-if="!isEdit && !isInputInterview"
                class="h4"
                @click="onClearCombinedPatients(patient)"
              ></b-icon-x>
            </span>
          </div>

          <div v-if="isErrorCbPatients" class="invalid-feedback d-block">
            Danh sách người tham gia là bắt buộc
          </div>
        </div>

        <!-- Người thực hiện -->
        <basic-select
          label="Người thực hiện"
          placeholder="---Chọn người thực hiện---"
          value-label="fullName"
          track-by="id"
          name="performer"
          changeValueByObject
          :options="performers"
          v-model="schedule.performer"
          v-validate="{
            required: true,
          }"
          :state="validateState('performer')"
          :invalidFeedback="errors.first('performer')"
          required
          data-vv-as="Người thực hiện"
          :disabled="isEdit"
        />

        <!-- Mục tiêu -->
        <basic-text-area
          :value.sync="schedule.goal"
          name="goal"
          label="Mục tiêu"
          placeholder="Nhập mục tiêu"
          maxLength="200"
        ></basic-text-area>

        <b-row>
          <b-col>
            <basic-select
              label="Loại"
              :value.sync="schedule.zoomType"
              :options="optionTypeGenerateZoom"
              v-validate="{
                required: true,
              }"
              track-by="id"
              value-label="name"
              :state="validateState('zoomType')"
              :invalidFeedback="errors.first('zoomType')"
              name="zoomType"
              required
              changeValueByObject
              :allowEmpty="false"
              placeholder="---Loại---"
            />
          </b-col>
        </b-row>
        <!--Host Zoom-->
        <!-- <b-row v-if="schedule.zoomType.id == 1">
          <b-col>
            <basic-select
              v-model="schedule.hostZoom"
              v-validate="{
                required: true,
              }"
              :state="validateState('hostZoom')"
              :invalidFeedback="errors.first('hostZoom')"
              :options="optionHostZooms"
              name="hostZoom"
              required
              data-vv-as="Host zoom"
              value-label="text"
              label="Host zoom"
              track-by="value"
              placeholder="Host zoom"
              changeValueByObject
            />
          </b-col>
        </b-row> -->
        <!-- Link Zoom -->
        <div v-if="schedule.zoomType.id == 2">
          <basic-input
            v-validate="{
              required: true,
              url: true,
              max: 200,
            }"
            required
            :value.sync="schedule.meetingLink"
            :state="validateState('meetingLink')"
            :invalidFeedback="errors.first('meetingLink')"
            :disabled="isEdit"
            name="meetingLink"
            data-vv-as="Link zoom"
            label="Link Zoom"
            placeholder="Thêm link Zoom"
            maxlength="200"
          />

          <!-- Room ID -->
          <basic-input
            v-validate="{
              max: 100,
            }"
            :value.sync="schedule.roomId"
            :state="validateState('roomId')"
            :invalidFeedback="errors.first('roomId')"
            :disabled="isEdit"
            name="roomId"
            data-vv-as="Room ID"
            label="Room ID"
            placeholder="Room ID"
            input-number
            maxlength="100"
          />

          <!-- Passcode -->
          <basic-input
            v-validate="{
              max: 100,
            }"
            :value.sync="schedule.passcode"
            :state="validateState('passcode')"
            :invalidFeedback="errors.first('passcode')"
            :disabled="isEdit"
            name="passcode"
            data-vv-as="Passcode"
            label="Passcode"
            placeholder="Passcode"
            maxlength="100"
          />
        </div>
        <!-- Actions -->
        <div class="d-flex justify-content-end align-items-center">
          <b-button class="btn mr-2" type="button" @click="onClickCancelButton">
            Hủy
          </b-button>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>

      <calendar-schedule-loop
        :type-form="typeForm"
        :data="schedule.calendarScheduler"
        :appt-date="schedule.appointmentDate"
        @save="onSaveScheduleLoop"
        @cancel="onCancelScheduleLoop"
      />
    </b-overlay>
  </b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations } = createNamespacedHelpers('calender');
import { calenderMixin } from './mixins/CalenderForm';
import {
  MODAL_STATUS,
  MODAL_TYPE,
  CALENDAR_TYPE,
  CALENDAR_REPEAT_TYPE,
} from '@/core/plugins/constants.js';
import uniqBy from 'lodash/uniqBy';
import { SET_LOADING } from '@/core/services/store/calender.module';

export default {
  name: 'CalendarScheduleForm',

  mixins: [calenderMixin],

  components: {
    CalendarScheduleLoop: () => import('./CalendarScheduleLoop'),
    DatePicker,
  },

  props: {
    typeForm: {
      type: String,
      required: true,
    },
    isInputInterview: {
      type: Boolean,
      default: false,
    },
    isCoach11: {
      type: Boolean,
      default: false,
    },
    isCoach1N: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      schedule: {
        name: '',
        type: '',
        appointmentDate: '',
        duration: 0,
        repeatType: '0',
        calendarScheduler: {},
        calendarAccounts: [],
        goal: '',
        meetingLink: '',
        performer: '',
        activity: '',
        hostZoom: null,
        zoomType: { id: 1, name: 'Auto' },
      },
      selectedCoaches: [],
      selectedPatients: null,
      fromTime: '',
      toTime: '',
      isErrorApptDate: false,
      disableRepeatType: false,
      isErrorCbPatients: false,
    };
  },

  computed: {
    ...mapState(['calendar', 'loading']),

    currentUser() {
      const user = this.$store.state.auth.user;
      return {
        ...user,
        accountId: user.id,
      };
    },

    isEdit() {
      return this.typeForm === MODAL_TYPE.EDIT;
    },

    isOneAndOne() {
      return this.schedule.type?.value === CALENDAR_TYPE.ONE_ONE.value;
    },

    isOneAndMultiple() {
      return this.schedule.type?.value === CALENDAR_TYPE.ONE_MULTIPLE.value;
    },

    isRequireGroup() {
      return [
        CALENDAR_TYPE.ONE_ONE.value,
        CALENDAR_TYPE.ONE_MULTIPLE.value,
      ].includes(this.schedule.type?.value);
    },

    isCustomRepeat() {
      const repeatType = this.isEdit
        ? this.schedule.repeatType.value
        : this.schedule.repeatType;
      return repeatType === CALENDAR_REPEAT_TYPE.CUSTOM;
    },

    computedTitle() {
      return this.isEdit ? 'Chỉnh sửa lịch hẹn' : 'Tạo lịch hẹn';
    },

    combinedPatients() {
      const coachPatients = (this.selectedCoaches || []).reduce((arr, item) => {
        return [...arr, ...(item?.patients || [])];
      }, []);

      let patients = [];
      if (this.selectedPatients) {
        patients = Array.isArray(this.selectedPatients)
          ? [...this.selectedPatients]
          : [this.selectedPatients];
      }

      return uniqBy([...coachPatients, ...patients], 'id');
    },

    computedRepeatText() {
      const apptDate = this.schedule.appointmentDate;
      const scheduler = this.schedule.calendarScheduler;

      const timeType = this.timeOptions
        .find((item) => item.value === scheduler.repeatType)
        ?.text?.toLowerCase();
      if (scheduler.repeatTime < 0 || !timeType) return;
      let text = `${scheduler.repeatTime} ${timeType} một lần`;

      // Weekly
      if (
        scheduler.repeatType === 1 &&
        scheduler.calendarSchedulerWeeks?.length
      ) {
        const weekDays = scheduler.calendarSchedulerWeeks
          .map((item) => item.dayInWeek)
          .sort((a, b) => a - b);
        const days = weekDays.map((item) => this.getDayText(item));
        text += ` vào ${days.join(', ')}`;
      }

      // Monthly
      if (scheduler.repeatType === 2 && apptDate) {
        text += ` vào ngày ${apptDate.split('/')[0]}`;
      }

      if (!scheduler.isRepeatInfinite) {
        text += `, cho tới ${this.formatShortDate(scheduler.endDate * 1000)}`;
      }

      return text;
    },

    validMinFromTime() {
      if (
        this.schedule.appointmentDate &&
        !this.isAfterToday(this.schedule.appointmentDate)
      ) {
        return this.nowTime;
      }

      return '';
    },

    isCoaching() {
      return this.isCoach11 || this.isCoach1N;
    },
  },

  watch: {
    typeForm() {
      this.setDataUpdate();
    },

    optionTypes(val = []) {
      if (val.length && this.isEdit) {
        this.schedule.type = this.optionTypes.find(
          (item) => item.value === this.calendar.type.toString(),
        );
      }

      if (this.isInputInterview) {
        this.schedule.type = this.optionTypes.find(
          (item) => item.value === CALENDAR_TYPE.INPUT_RATING.value,
        );
      }

      if (this.isCoach11) {
        this.schedule.type = this.optionTypes.find(
          (item) => item.value === CALENDAR_TYPE.ONE_ONE.value,
        );
      }

      if (this.isCoach1N) {
        this.schedule.type = this.optionTypes.find(
          (item) => item.value === CALENDAR_TYPE.ONE_MULTIPLE.value,
        );
      }
    },

    isOneAndOne(val) {
      if (val) this.selectedCoaches = [];
    },

    formRepeatTypes(val = []) {
      if (!val.length) return;

      // Auto select first element in Create form
      if (!this.isEdit) {
        this.schedule.repeatType = { ...val[0] };
      } else {
        this.schedule.repeatType = this.formRepeatTypes.find(
          (item) => item.value === this.calendar.repeatType.toString(),
        );
      }
    },
  },

  mounted() {
    this.setDataUpdate();
  },

  methods: {
    ...mapMutations({ SET_LOADING }),

    setDataUpdate() {
      if (!this.isEdit) return;

      const data = { ...this.calendar };
      this.schedule.name = data.name;
      this.schedule.appointmentDate = this.formatDateLocale(
        data.appointmentDate * 1000,
      );
      this.fromTime = this.getTimeOfDate({ date: data.appointmentDate * 1000 });
      this.toTime = this.getTimeOfDate({
        date: (data.appointmentDate + data.duration) * 1000,
      });
      this.selectedPatients = [...(data.calendarAccounts || [])].map(
        (item) => ({
          accountId: item.accountId,
          fullName: item.account.fullName,
        }),
      );
      this.schedule.calendarScheduler = { ...data.calendarScheduler };
      this.schedule.goal = data.goal;
      this.schedule.meetingLink = data.meetingLink;
      this.schedule.roomId = data.roomId;
      this.schedule.passcode = data.meetingPassword;
      this.schedule.performer = this.performers.find(
        (el) => el.id === data.performerId,
      );
    },

    handleValidate() {
      this.validateCbPatients();
      this.validateApptDate();
      this.$validator.validateAll().then((result) => {
        if (!result || this.isErrorCbPatients || this.isErrorApptDate) return;

        this.handleSubmit();
      });
    },

    handleSubmit() {
      let data = {};
      if (this.isEdit) {
        data = {
          id: this.calendar.id,
          name: this.schedule.name,
          goal: this.schedule.goal,
          meetingLink: this.schedule.meetingLink,
          roomId: this.schedule.roomId,
          meetingPassword: this.schedule.passcode,
          modelStatus: MODAL_STATUS.CHANGED,
          performerId: this.schedule.performer.id,
        };
      } else {
        data = this.handleGetDataForm();
      }
      this.$emit('save', { data, typeForm: this.typeForm });
    },

    handleGetDataForm() {
      const formData = { ...this.schedule };
      const data = {
        name: formData.name,
        appointmentDate: this.getDateSeconds(
          `${formData.appointmentDate} ${this.fromTime}`,
        ),
        duration: this.getDurationTimes(this.fromTime, this.toTime),
        repeatType: formData.repeatType,
        modelStatus: MODAL_STATUS.NEW,
        meetingLink: formData.meetingLink,
        performerId: formData.performer.id,
        //hostZoomId: formData.zoomType.id === 1 ? formData.hostZoom.value : null,
        zoomTypeId: formData.zoomType.id,
      };

      if (data.repeatType instanceof Object)
        data.repeatType = data.repeatType.value;
      if (formData.type.value > -1) data.type = formData.type.value;
      if (formData.calendarScheduler.repeatType > -1)
        data.calendarScheduler = { ...formData.calendarScheduler };
      if (this.combinedPatients.length) {
        data.calendarAccounts = this.combinedPatients.map((item) => {
          const account = {
            accountId: item.accountId,
            modelStatus: MODAL_STATUS.NEW,
          };
          if (this.calendarId) account.calendarId = this.calendarId;

          return account;
        });
      }
      if (formData.goal) data.goal = formData.goal;
      if (formData.passcode) data.meetingPassword = formData.passcode;
      if (formData.roomId) data.roomId = formData.roomId;
      if (this.selectedCoaches.length)
        data.trainingGroupIds = this.selectedCoaches.map((el) => el.id);

      if (this.isOneAndMultiple) {
        data.calendarActivity = Number(formData.activity);
      }

      return data;
    },

    handleResetForm() {
      this.isErrorCbPatients = false;
      this.isErrorApptDate = false;

      this.schedule = {
        name: '',
        type: '',
        appointmentDate: '',
        duration: 0,
        repeatType: { ...this.formRepeatTypes[0] },
        calendarScheduler: {},
        calendarAccounts: [],
        goal: '',
        meetingLink: '',
        hostZoom: null,
        zoomType: { id: 1, name: 'Auto' },
      };
      this.selectedCoaches = [];
      this.selectedPatients = [];
      this.fromTime = '';
      this.toTime = '';

      // In mixin file
      this.loadedPatients.length = 0;
      this.totalLoadedPatients = 0;
      this.pgnPatients = {
        page: 1,
        size: 10,
      };

      this.$emit('hide');
    },

    onClickCancelButton() {
      this.$bvModal.hide('calendar-schedule-add-modal');
    },

    onSaveScheduleLoop(val) {
      const data = {
        repeatTime: val.repeatTime,
        repeatType: val.repeatType,
        isRepeatInfinite: !val.isEnd,
        modelStatus: this.isEdit ? MODAL_STATUS.CHANGED : MODAL_STATUS.NEW,
      };

      // If type = week
      if (val.repeatType === 1) {
        data.calendarSchedulerWeeks = val.daysInWeek.map((item) => {
          const temp = {
            dayInWeek: item,
            modelStatus: this.isEdit ? MODAL_STATUS.CHANGED : MODAL_STATUS.NEW,
          };
          if (this.calendarId) temp.calendarSchedulerId = this.calendarId;

          return temp;
        });
      }

      if (val.isEnd) {
        data.endDate = this.getDateSeconds(val.endDate);
      }

      this.schedule.calendarScheduler = { ...data };
    },

    onCancelScheduleLoop() {
      this.schedule.repeatType = { ...this.formRepeatTypes[0] };
    },

    onChangeLoopSchedule(val) {
      this.resetCalendarScheduler();
      if (val === '4') {
        this.$bvModal.show('calendar-schedule-loop-modal');
      }
    },

    onClearCombinedPatients(val) {
      this.handleClearPatients(val);
      this.handleClearCoachPatients(val);
    },

    handleClearPatients(val) {
      // Clear items of selected patients
      if (this.isOneAndOne) {
        this.selectedPatients = null;
      } else {
        const idPatient = (this.selectedPatients || []).findIndex(
          (item) => item.accountId === val.accountId,
        );
        if (idPatient > -1) this.selectedPatients.splice(idPatient, 1);
      }
    },

    handleClearCoachPatients(val) {
      // Clear items of patients in selected coaches
      for (let i = 0; i < this.selectedCoaches.length; i++) {
        const idPatientCoach = this.selectedCoaches[i].patients.findIndex(
          (item) => item.accountId === val.accountId,
        );
        if (idPatientCoach > -1) {
          this.selectedCoaches[i].patients.splice(idPatientCoach, 1);
        }

        // If patients of one coach is empty, then clear corresponding coach
        // if (!this.selectedCoaches[i].patients.length) {
        //   this.selectedCoaches.splice(idPatientCoach, 1);
        // }
      }
    },

    validateApptDate() {
      const isCustomRepeatType =
        this.schedule.repeatType === CALENDAR_REPEAT_TYPE.CUSTOM;
      const isEndDate = !this.schedule.calendarScheduler.isRepeatInfinite;
      const isAfterEnd =
        this.getDateSeconds(this.schedule.appointmentDate) >=
        this.schedule.calendarScheduler.endDate;

      this.isErrorApptDate = isCustomRepeatType && isEndDate && isAfterEnd;
      return this.isErrorApptDate;
    },

    async onInputType(val) {
      val && (await this.$validator.reset({ name: 'type' }));

      // If Type is Phỏng vấn đầu ra,
      // then auto select Repeat type is Không lặp lại and disable
      if (val === CALENDAR_TYPE.OUTPUT_CLASSIFICATION.value) {
        this.schedule.repeatType = { ...this.formRepeatTypes[0] };
        this.resetCalendarScheduler();
        this.disableRepeatType = true;
        return;
      }

      this.selectedPatients = null;
      this.disableRepeatType = false;
    },

    resetCalendarScheduler() {
      this.schedule.calendarScheduler = {};
    },

    async onShowForm() {
      this.SET_LOADING(true);
      await this.GET_PREPARE_FORM();
      if (this.isInputInterview) {
        this.schedule.name = 'Phỏng vấn đầu vào';
        this.selectedPatients = [this.customer, this.currentUser];
      }

      if (this.isCoach11) {
        this.schedule.name = '1-1';
        this.selectedPatients = { ...this.propPatient };
        this.loadMorePatients();
      }

      if (this.isCoach1N) {
        this.schedule.name = '1-N';
        this.selectedCoaches = this.computedCoaches.filter(
          (el) => el.id === this.filterGroup.trainingGroupId,
        );
      }
      this.setCurrentTime();
      this.SET_LOADING(false);
    },

    validateCbPatients() {
      this.isErrorCbPatients = !this.combinedPatients.length;
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },

    selectFromTime(value) {
      if (this.isInputInterview && value) {
        const [hh, mm] = value.split(':');
        this.toTime = `${Number(hh) + 1}:${mm}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calender-time::v-deep {
  label {
    padding-right: 0.25rem !important;
  }
}

.patients {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  border: 1px solid #e4e6ef;
  padding: 0.65rem;
  border-radius: 0.42rem;
  min-height: 72px;
  max-height: 150px;
  overflow-y: auto;

  &__item {
    display: flex;
    align-items: center;
    height: 30px;
    background-color: #f3f6f9;
    padding: 4px 28px 4px 8px;
    border-radius: 8px;
    position: relative;

    .b-icon {
      margin-bottom: 0;
      position: absolute;
      height: 30px;
      right: 0;
      width: 22px;
      border-radius: 0 8px 8px 0;
      color: black;

      &:hover {
        cursor: pointer;
        color: #fff;
        background-color: red;
      }
    }
  }

  &.is-invalid {
    border-color: #f64e60;
  }
}

.is-invalid::v-deep {
  position: relative;

  input {
    border-color: #f64e60 !important;
  }

  .valid-mess {
    position: relative;
    top: -20px;
  }
}
</style>
