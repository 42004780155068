import uniqBy from 'lodash/uniqBy';
import { createNamespacedHelpers, mapState } from 'vuex';
import {
  GET_PREPARE_FORM,
  GET_PATIENTS,
} from '@/core/services/store/calender.module';
const { mapActions } = createNamespacedHelpers('calender');
import { CALENDAR_TYPE } from '@/core/plugins/constants.js';

export const calenderMixin = {
  props: {
    propPatient: {
      type: Object,
      default: () => {},
    },
    filterGroup: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dateFomartion: 'DD/MM/YYYY',
      nowDate: '',
      nowTime: '',

      timeOptions: [
        { text: 'Ngày', value: 0 },
        { text: 'Tuần', value: 1 },
        { text: 'Tháng', value: 2 },
      ],

      isLoadPatients: false,
      loadedPatients: [],
      totalLoadedPatients: 0,
      pgnPatients: {
        page: 1,
        size: 10,
      },
    };
  },

  computed: {
    ...mapState('calender', [
      'coaches',
      'patients',
      'formRepeatTypes',
      'formTypes',
      'performers',
      'activites',
      'hostZooms',
    ]),

    ...mapState('groupDashboard', ['groups']),

    computedCoaches() {
      return this.isCoach1N ? this.groups : this.coaches;
    },

    computedPatients() {
      return this.isCoach11 ? this.loadedPatients : this.patients;
    },

    optionTypes() {
      if (this.isInputInterview) return this.formTypes;
      return this.formTypes.filter((item) => {
        return item.value !== CALENDAR_TYPE.INPUT_RATING.value;
      });
    },
    optionHostZooms() {
      return this.hostZooms;
    },
    optionTypeGenerateZoom() {
      return [
        { id: 1, name: 'Auto' },
        { id: 2, name: 'Manuals' },
      ];
    },
  },

  created() {
    this.setDateBefore();
  },

  methods: {
    ...mapActions({ GET_PREPARE_FORM, GET_PATIENTS }),

    setDateBefore() {
      const d = new Date();
      this.nowDate = d.setDate(d.getDate() - 1);
    },

    setCurrentTime() {
      this.nowTime = this.getTimeOfDate({
        date: new Date(),
        showSecond: true,
      });
    },

    getDurationTimes(start, end) {
      return this.convertTimeToSecond(end) - this.convertTimeToSecond(start);
    },

    getDateSeconds(date) {
      return this.formatDate(date).valueOf() / 1000;
    },

    getDayText(day) {
      switch (day) {
        case 0:
          return 'chủ nhật';
        case 1:
          return 'thứ 2';
        case 2:
          return 'thứ 3';
        case 3:
          return 'thứ 4';
        case 4:
          return 'thứ 5';
        case 5:
          return 'thứ 6';
        case 6:
          return 'thứ 7';

        default:
          return 'chủ nhật';
      }
    },

    async loadMorePatients() {
      this.isLoadPatients = true;
      try {
        const { total, items } = await this.GET_PATIENTS({
          ...this.filterGroup,
          ...this.pgnPatients,
          isPackageExpired: false,
        });
        this.pgnPatients.page++;

        this.loadedPatients = uniqBy(
          [this.propPatient, ...this.loadedPatients, ...(items || [])],
          'accountId',
        );
        this.totalLoadedPatients = total;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadPatients = false;
      }
    },

    isAfterToday(date) {
      const d = this.formatDate(date);
      const today = this.formatDate();

      if (d.diff(today, 'days') === 0) return d.date() > today.date();
      return d.diff(today, 'days') > 0;
    },

    formatDate(date) {
      if (!date) return this.$moment();
      return this.$moment(date, 'DD/MM/YYYY HH:mm:ss');
    },

    formatShortDate(date) {
      if (!date) return this.$moment();
      return this.$moment(date).format('DD/MM/YYYY');
    },

    disabledBeforeTodayAndAfterTwoWeek(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (!this.isInputInterview) {
        return date < today;
      }

      return (
        date < today || date > new Date(today.getTime() + 14 * 24 * 3600 * 1000)
      );
    },
  },
};
